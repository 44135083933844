import React, { useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { matchSorter } from 'match-sorter';
import { XIcon } from '@heroicons/react/outline';
import { keywordAtom, menuAtom, itemAtom } from '../../../atoms/Atoms';
import './Popup.css'; // Import your styles

function Popup({ onClose, photo, details, price, cta, search }) {
  const [keyword, setKeyword] = useRecoilState(keywordAtom);
  const setItem = useSetRecoilState(itemAtom);
  const menu = useRecoilValue(menuAtom);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button type="button" className="close-button" onClick={onClose}>
          <XIcon className="w-4 h-4 text-gray-700" />
        </button>
        {photo !== null ?
          <div className="image-container">
            <img src={photo} alt="Product" className="popup-image" />
          </div>
        : null}
        <p>{details}</p>
        <p className="popup-price"><span style={{ color: 'red' }}>{price}</span></p>
        <button
          type="button"
          className="add-to-cart-button"
          onClick={() => {
            onClose();
            if (search !== null) {
              // setKeyword(search);

              const tempItems = _.map(menu?.sections, (category) =>
                _.map(
                  matchSorter(category.items, search, {
                    keys: ['name'],
                  }),
                  (i) => i
                )
              );

              if (tempItems.length > 0) {
                if (tempItems[0].length > 0) {
                  setItem(tempItems[0][0]);
                }
              }
            }
          }}
        >
          {cta}
        </button>
      </div>
    </div>
  );
}

export default Popup;
